import { combineReducers } from 'redux';
import theme from './theme/themeSlice';
import auth from './auth';
import base from './base';
import locale from './locale/localeSlice';
import organisation from './organisation/organisationSlice';
import servers from './servers/serversSlice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        organisation,
        servers,
        ...asyncReducers,
    });
    return combinedReducer(state, action);
};

export default rootReducer;
