import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import history from './history';
import './locales';
import { useAuth } from 'react-oidc-context';
import jwt_decode from 'jwt-decode';

import { Button } from 'components/ui';
import { setUser } from 'store/auth/userSlice';

function App() {
    const auth = useAuth();
    const accessToken = auth.user?.access_token;

    const signout = async () => {
        localStorage.removeItem('currentOrg');
        localStorage.removeItem('orgList');
        await auth.revokeTokens();
        await auth.removeUser();
        auth.signoutRedirect();
    };

    if (auth.activeNavigator === 'signinSilent') {
        return <div>Signing you in...</div>;
    }
    if (auth.activeNavigator === 'signoutRedirect') {
        return <div>Signing you out...</div>;
    }
    if (auth.isLoading) {
        return <div>Loading...</div>;
    }
    if (auth.error) {
        return (
            <div>
                ERROR: "{auth.error.message}"
                <div>
                    <Button size="sm" variant="solid" onClick={signout}>
                        sign out
                    </Button>
                </div>
            </div>
        );
    }
    if (auth.isAuthenticated) {
        if (accessToken) {
            try {
                const decodedToken = jwt_decode(accessToken);
                const getRealmAccess = decodedToken.realm_access.roles;
                if (getRealmAccess.includes('super-user')) {
                    store.dispatch(
                        setUser({
                            email: decodedToken.email,
                            sub: decodedToken.sub,
                            authority: ['admin'],
                        })
                    );
                } else {
                    store.dispatch(
                        setUser({
                            email: decodedToken.email,
                            sub: decodedToken.sub,
                            authority: ['user'],
                        })
                    );
                }
            } catch (error) {
                console.error('Erreur lors du décodage du jeton :', error);
            }
        }
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <Theme>
                            <Layout />
                        </Theme>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        );
    }
    // redirect to keycloak if not logged-in
    auth.signinRedirect();
}

export default App;
