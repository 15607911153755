import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';

if (process.env.REACT_APP_ENV !== 'production') {
    const metaTagNoIndex = document.createElement('meta');
    metaTagNoIndex.name = 'robots';
    metaTagNoIndex.content = 'noindex,nofollow';
    document.getElementsByTagName('head')[0].appendChild(metaTagNoIndex);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const realm = process.env.REACT_APP_AUTH_REALM;
const authServerUrl = process.env.REACT_APP_AUTH_SERVER;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URI;
const oidcConfig = {
    authority: `${authServerUrl}/realms/${realm}`,
    client_id: clientId,
    redirect_uri: redirectUri,
    onSigninCallback: (_) =>
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        ),
};

root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <App />
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
